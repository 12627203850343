const API_URL = process.env.REACT_APP_BACKEND_URL;

const fullUrl = (path) => {
    return `${API_URL}${path}`;
};

export const api = {
    auth: {
        signUp: fullUrl("/auth/signup"),
        check: fullUrl("/auth/check"),
        signIn: fullUrl("/auth/signin"),
        whoami: fullUrl("/auth/whoami"),
        goal: fullUrl("/auth/onboarding/goal"),
        bio: fullUrl("/auth/onboarding/bio"),
        bioText: fullUrl("/auth/onboarding/bioText"),
        niche: fullUrl("/auth/onboarding/niche"),
        socialLinks: fullUrl("/auth/onboarding/social-links"),
        template: fullUrl("/auth/onboarding/template"),
    },
    shopAuth: {
        signIn: fullUrl("/shopAuth/signin"),
        whoami: fullUrl("/shopAuth/whoami"),
    },
    bookings: {
        create: fullUrl("/bookings/create-booking"),
        fetch: fullUrl("/auth/bookings"),
        update: (id) => fullUrl(`/auth/bookings/${id}`),
        delete: (id) => fullUrl(`/auth/bookings/${id}`),
    },
    store: {
        userStoreData: (username) => fullUrl(`/store/${username}`),
        updateViewCount: (username) => fullUrl(`/store/${username}/view`),
        updateShopViewCount: (storeName) => fullUrl(`/store/${storeName}/shop-view`),
        updateProductClick: (storeName, productId) => fullUrl(`/store/${storeName}/product/${productId}/click`),
        checkout: (username, productSlug) => fullUrl(`/store/${username}/checkout/${productSlug}`),
        addEmailUser: (storeName) => fullUrl(`/store/${storeName}/emails`),
        createCustomerPaymentIntent: (storeName) => fullUrl(`/store/${storeName}/create-payment-intent`),
        onPaymentSuccess: (storeName) => fullUrl(`/store/${storeName}/payment-success`),
        processFreeBooking: (storeName) => fullUrl(`/store/${storeName}/process-free-booking`),
        getDownloadLink: (storeName) => fullUrl(`/store/${storeName}/download`),
        claimAccount: (storeName) => fullUrl(`/store/${storeName}/claim-account`),
        processFreeDownload: (storeName) => fullUrl(`/store/${storeName}/process-free-download`),
        fetchCommerceProducts: (storeName) => fullUrl(`/store/${storeName}/get-commerce-products`),
    },
    billing: {
        setup: () => fullUrl('/billing/intent/setup'),
        confirm: () => fullUrl('/billing/intent/confirm/no-redirect'),
        createSubscription: () => fullUrl('/billing/subscription/create'),
        getBillingPlan: () => fullUrl('/billing/plan/get'),
        addOrUpdatePaymentMethod: () => fullUrl('/billing/card/add-or-update'),
        createAccountLink: () => fullUrl('/billing/account/create-account-link'),
        deleteAccountLink: () => fullUrl('/billing/account/delete-account-link'),
        updateAccount: () => fullUrl('/billing/account/update-account-link'),
        cancelSubscription: () => fullUrl('/billing/subscription/cancel'),
        detachCard: fullUrl('/billing/card/detach'),
    },
    customers: {
        fetchCustomers: fullUrl("/customers/get-customers"),
        fetchEmails: fullUrl("/customers/get-emails"),
        fetchCustomerById: (id) => fullUrl(`/customers/get-customer/${id}`),
        fetchEmailById: (id) => fullUrl(`/customers/get-email/${id}`),
    },
    userProfile: {
        fetch: fullUrl("/auth/profile/user-profile"),
        update: fullUrl("/auth/profile/user-profile/:id"),
        updateDetails: fullUrl("/auth/profile/user-profile-details/:id"),
        updatePassword: fullUrl("/auth/profile/update-password"),
        updateAnalytics: fullUrl("/auth/profile/update-analytics"),
        updateAddress: fullUrl("/auth/profile/update-address"),
        updateOtherSettings: fullUrl("/auth/profile/update-other-settings"),
        fetchTheme: fullUrl("/auth/profile/theme"),
        updateDashboardVisit: fullUrl("/auth/profile/user-profile/update-dashboard-visit"),
        updateVerificationStatus: fullUrl("/auth/profile/update-verification"),
    },
    theme: {
        saveCardStyle: fullUrl("/auth/profile/save-card-style"),
        saveButtonStyle: fullUrl("/auth/profile/save-button-style"),
        saveBackground: fullUrl("/auth/profile/save-background"),
        uploadBackgroundImage: fullUrl("/auth/profile/upload-background-image"),
        uploadBackgroundVideo: fullUrl("/auth/profile/upload-background-video"),
        saveSelectedTheme: fullUrl("/auth/profile/save-selected-theme"),
        fetchSparkleTheme: fullUrl("/auth/profile/get-sparkle-theme"),
        saveImageStyle: fullUrl("/auth/profile/save-image-style"),
        saveFontStyle: fullUrl("/auth/profile/save-font-style"),
    },
    products: {
        create: fullUrl("/auth/store/create-product"),
        fetch: fullUrl("/auth/store/get-products"),
        fetchEdit: fullUrl("/auth/store/edit/:type/:id"),
        delete: fullUrl("/auth/store/delete-product/:id"),
        deleteBooking: fullUrl("/auth/store/delete-booking-product/:id"),
        update: fullUrl("/auth/store/update-product/:type/:id"),
        unpublish: fullUrl("/auth/store/unpublish-product/:id"),
        publish: fullUrl("/auth/store/publish-product/:id"),
        createCommerceProduct: fullUrl("/auth/store/create-commerce-product"),
        fetchCommerceProduct: fullUrl("/auth/store/commerce-product/:id"),
        updateCommerceProduct: fullUrl("/auth/store/update-commerce-product/:id"),
        deleteCommerceProduct: fullUrl("/auth/store/delete-commerce-product/:id"),
        scrapeProduct: fullUrl("/auth/store/scrape-product"),
        updatePublishStatus: (id) => fullUrl(`/auth/store/update-product-publish-status/${id}`),
        fetchShopSettingsandMetrics: fullUrl("/auth/store/get-shop-settings"),
        updateShopSettings: fullUrl("/auth/store/update-shop-settings"),
    },
    headlineSections: {
        create: fullUrl("/auth/store/create-headline"),
        fetch: fullUrl("/auth/store/get-headlines"),
        delete: fullUrl("/auth/store/delete-headline/:id"),
        update: fullUrl("/auth/store/update-headline/:id"),
    },
    block: {
        update: fullUrl("/auth/store/update-block-order")
    },
    metrics: {
        fetch: fullUrl("/metrics/get-metrics"),
        getShopMetrics: fullUrl("/metrics/get-shop-metrics"),
    },
    transactions: {
        fetch: fullUrl("/metrics/get-transactions"),
        fetchById: (id) => fullUrl(`/metrics/get-transaction/${id}`),
        refund: (id) => fullUrl(`/billing/customer/refund-transaction/${id}`),
    },
    referrals: {
        fetch: fullUrl("/auth/profile/get-referral-data"),
    },
    services: {
        checkUserNameAvailablity: fullUrl("/auth/check-username"),
    },
    tools: {
        generate: fullUrl("/auth/generate-business-name"),
        domainCheck: fullUrl("/auth/generate-domain-name"),
        generateFreeImage: fullUrl("/auth/generate-image-generator"),
    },
    customize: {
        fetchImage: fullUrl("/auth/get-ai-image"),
    },
    shop: {
        fetchCategories: fullUrl("/shop/categories"),
        createCategory: fullUrl("/shop/categories"),
        getCategoryById: (id) => fullUrl(`/shop/categories/${id}`),
        updateCategory: (id) => fullUrl(`/shop/categories/${id}`),
        updateCategoryStatus: (id) => fullUrl(`/shop/categories/${id}/status`),
        updateCategorySortOrder: (id) => fullUrl(`/shop/categories/${id}/sort-order`),
        getCollectionsForCategory: (id) => fullUrl(`/shop/categories/${id}/collections`),
        deleteCategory: (id) => fullUrl(`/shop/categories/${id}`),
        
        fetchCollections: fullUrl("/shop/collections"),
        createCollection: fullUrl("/shop/collections"),
        getCollectionById: (id) => fullUrl(`/shop/collections/${id}`),
        updateCollection: (id) => fullUrl(`/shop/collections/${id}`),
        deleteCollection: (id) => fullUrl(`/shop/collections/${id}`),
        updateCollectionStatus: (id) => fullUrl(`/shop/collections/${id}/status`),
        
        fetchProducts: fullUrl("/shop/products"),
        createProduct: fullUrl("/shop/products"),
        fetchProduct: (id) => fullUrl(`/shop/products/${id}`),
        updateProduct: (id) => fullUrl(`/shop/products/${id}`),
        deleteProduct: (id) => fullUrl(`/shop/products/${id}`),
        deleteProductImage: (productId) => fullUrl(`/shop/products/${productId}/images`),
        
        uploadImage: fullUrl("/shop/upload-image"),
        
        fetchOrders: fullUrl("/shop/orders"),
        updateOrderStatus: (id) => fullUrl(`/shop/orders/${id}/status`),
        
        fetchShopMetrics: fullUrl("/shop/metrics"),
    },
};

export default api;